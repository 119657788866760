<template>
    <!--列表页-->
    <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
        <div style="color: #999;font-size: 14px;line-height: 50px;width: 1200px;margin:0 auto;">
            当前位置 <span style="margin: 0 10px">/</span> 首页 <span style="margin: 0 10px">/</span> <span
                style="color: #1890FF">科技企业库</span>
        </div>
        <div class="center" id="erprise_list">
            <!--      列表-->
            <div class="gd-list">
                <div v-for="(item,index) in list"  class="list-item">
                    <div class="item-head">
                        <div class="head-fs">{{item.logoname}}</div>
                    </div>
                    <div class="content-box">
                        <div class="name-fs">{{item.name}}</div>
                        <div class="scale-fs"><span class="scale-fs-sitem"  v-for="(sitem,index) of item.scalelist">{{sitem}}</span></div>
                        <div class="copyright-fs">
                            知识产权：专利 <span> {{item.ip}}</span>个
                            <span style="margin-right: 10px"></span>
                            商标<span>{{item.trademark}}</span>个
                            <span style="margin-right: 10px"></span>
                            版权<span>{{item.copyright}}</span>个
                        </div>
                        <div class="leader-fs">
                            法人代表：<span style="margin-right: 40px;color: #1890FF;">{{item.legalPerson}}</span>
                            所属行业：<span style="color: #666;">{{item.industry}}</span>
                        </div>
                        <div class="address-fs">
                            所在地区：<span style="margin-right: 40px;color: #666;">{{item.add}}</span>
                            详细地址：<span style="color: #666;">{{item.address}}</span>
                        </div>
                        <div class="desc-fs">{{item.type}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div STYLE="overflow: hidden;width:100%;width: 1200px;margin: 0 auto">
            <Page
                    :total="listTotalNum"
                    :page-size="pageSize"
                    :current="pageNum"
                    show-elevator
                    style="margin-top: 30px; float: right; margin-bottom: 30px"
                    @on-change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import bgimg from "@/assets/image/bgimg.png";

    export default {
        name: "ErpriseList",
        data() {
            return {
                list: [],
                bgimg: bgimg,
                listTotalNum: 0,
                pageSize: 6,
                pageNum: 1
            }
        },
        async mounted() {
            this.listTotalNum = datalist.length
            this.list = datalist.slice(0,6)
            this.setlist()
        },

        computed: {},
        methods: {
            changePage(index) {
                this.pageNum = index;
                this.list = datalist.slice(6*(this.pageNum-1),6*(this.pageNum))
                this.setlist()
            },
            //数据处理
            setlist(){
                this.list = this.list.map(v => {
                    v.logoname = v.name.slice(0,6)
                    v.scalelist = v.scale.split(',')
                    return v
                })
            }
        }
    };
</script>


<style scoped lang="scss">
    /deep/ .ivu-page-item-active {
        background-color: #2d8cf0;
        color: white !important;
        border: none;
    }

    /deep/ .ivu-page-item-active a {
        color: white !important;
    }

    .erprise_list > div:hover {
        box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.1);
    }

    .service_div {
        font-size: 14px;
        line-height: 32px;
        margin-top: 9px;

        & span {
            display: inline-block;
            height: 32px;
            margin-right: 20px;
            cursor: pointer;
            color: #333;
            padding: 0 10px;
        }
    }

    .select_span {
        text-align: center;
        background: #1890FF;
        border-radius: 4px;
        color: #fff !important;
    }

    .dpyx {
        & span {
            margin: 0 5px;
        }
    }

    .left_desc {
        padding-left: 20px;
        padding-top: 5px;
    }

    .rmzl_content_real {
        padding: 20px 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .center {
        width: 1200px;
        margin: 0 auto;
    }

    .list-item {
        width: 590px;
        min-height: 238px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #E5E8ED;
        padding: 20px;
        display: flex;
        &:hover {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
        }
    }

    .gd-list {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        justify-items: center;
        grid-row-gap: 20px;
        align-items: stretch;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .item-head{
        width: 80px;
        min-width: 80px;
        height: 80px;
        background: #53ABFC;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        padding: 12px;
    }
    .head-fs{
        width: 90px;
        font-size: 18px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        line-height: 25px;
    }

    .name-fs{
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 26px;
        margin-top: -5px;
    }
    .scale-fs{
        display: flex;
    }

    .scale-fs-sitem{
        font-size: 12px;
        color: #1890FF;
        line-height: 22px;
        padding: 0 6px;
        background: #E7F4FF;
        border-radius: 4px;
        margin-right: 16px;
        margin-top: 10px;
    }

    .copyright-fs,.leader-fs,.address-fs{
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
    }

    .copyright-fs span{
        color: #FF5200;
        margin: 0 4px;
    }

    .desc-fs{
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        margin-top: 13px;
    }
</style>
